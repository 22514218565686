// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Card,
  CardHeader,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  Progress,
  useColorModeValue
} from '@chakra-ui/react';

const AdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    weeklyProgress: 0,
    weeklyGoal: 10,
    users: []
  });

  useEffect(() => {
    fetchDashboardStats();
    // Refresh every 5 minutes
    const interval = setInterval(fetchDashboardStats, 300000);
    return () => clearInterval(interval);
  }, []);

  const fetchDashboardStats = async () => {
    try {
      const response = await fetch('/api/admin/dashboard-stats', {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch dashboard data');
      const data = await response.json();
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }
  };

  const calculateProgress = () => {
    return (dashboardData.weeklyProgress / dashboardData.weeklyGoal) * 100;
  };

  return (
    <Stack spacing={6}>
      <Box p={4}>
        <Heading size="lg" mb={6}>Admin Dashboard</Heading>

        {/* Stats Cards */}
        <Stack direction={['column', 'row']} spacing={6} mb={6}>
          <Card flex={1}>
            <CardHeader>
              <Stat>
                <StatLabel fontSize="lg">Total Users</StatLabel>
                <StatNumber>{dashboardData.totalUsers.toLocaleString()}</StatNumber>
              </Stat>
            </CardHeader>
          </Card>

          <Card flex={1}>
            <CardHeader>
              <Stat>
                <StatLabel fontSize="lg">Weekly New Users</StatLabel>
                <StatNumber>
                  {dashboardData.weeklyProgress} / {dashboardData.weeklyGoal}
                </StatNumber>
                <Progress 
                  value={calculateProgress()} 
                  mt={2}
                  colorScheme="purple"
                  borderRadius="full"
                />
              </Stat>
            </CardHeader>
          </Card>
        </Stack>

        {/* Users Table */}
        <Card>
          <CardHeader>
            <Heading size="md">Recent Users</Heading>
          </CardHeader>
          <CardBody>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Email</Th>
                    <Th>Join Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dashboardData.users.map((user, index) => (
                    <Tr key={index}>
                      <Td>{user.email}</Td>
                      <Td>
                        {new Date(user.joinDate).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </Stack>
  );
};

export default AdminDashboard;
