// src/Components/Notes/NotesComponent.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const NotesComponent = () => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleBack = () => {
    navigate('/user-profile');
  };

  const fetchNotes = async () => {
    if (!isAuthenticated) return;

    try {
      setError(null);  // Clear any previous errors
      const response = await fetch('https://sbscoaches.com/api/notes', {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch notes');
      }
      const data = await response.json();
      setNotes(data);
    } catch (error) {
      console.error('Error fetching notes:', error);
      setError('Unable to load notes. Please try again later.');  // Set user-friendly error
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async (noteId) => {
    try {
      const response = await fetch(`https://sbscoaches.com/api/notes/${noteId}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to delete note');
      }

      // Update local state to remove the deleted note
      setNotes(prevNotes => prevNotes.filter(note => note.id !== noteId));
    } catch (error) {
      console.error('Error deleting note:', error);
      setError('Unable to delete note. Please try again later.');
    }
  };

  const copyToClipboard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      // Could add a small toast notification here
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  useEffect(() => {
    fetchNotes();

    // Refresh notes every 30 seconds to catch new voice-saved notes
    const interval = setInterval(fetchNotes, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <div>Loading notes...</div>;
  }

  return (
    <div className="p-4">
      {/* Back Button */}
      <button 
        onClick={handleBack} 
        className="flex items-center text-gray-600 hover:text-gray-800 mb-4"
      >
        <svg 
          className="w-4 h-4 mr-1" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Return to Profile
      </button>
      <h2 className="text-xl font-bold mb-4">My Notes</h2>
      {/* Add error message display here */}
      {error && (
        <div className="text-red-500 mb-4">
          {error}
        </div>
      )}
      {loading ? (
        <div>Loading notes...</div>
      ) : notes.length === 0 ? (
        <p>No notes yet. Try saying "save note" to create one!</p>
      ) : (
        <div className="space-y-4">
          {notes.map((note) => (
            <div 
              key={note.id} 
              className="p-4 border rounded shadow hover:shadow-md"
            >
              <div className="flex justify-between items-start">
                <p className="text-gray-600 text-sm">
                  {new Date(note.created_at).toLocaleString()}
                </p>
                <div className="flex space-x-2">
                  <button
                    onClick={() => copyToClipboard(note.content)}
                    className="text-sm text-blue-500 hover:text-blue-700"
                  >
                    Copy
                  </button>
                  <button
                    onClick={() => deleteNote(note.id)}
                    className="text-sm text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <p className="mt-2">{note.content}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotesComponent;
