import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  VStack,
  Button,
  useColorModeValue,
  Icon,
  Text,
  useToast,
  Progress,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Stack,
  Divider,
} from '@chakra-ui/react';
// Add FaSync to the imports
import { FaCreditCard, FaLock, FaCalendar, FaCoins, FaSync } from 'react-icons/fa';
import ResetPassword from './ResetPassword';
import ScheduleCoaching from './ScheduleCoaching';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const AccountSettings = () => {
  const { isAuthenticated } = useAuth();
  const toast = useToast();
  const bg = useColorModeValue('purple.50', 'purple.900');
  const color = useColorModeValue('gray.800', 'white');
  const tabBg = useColorModeValue('white', 'gray.800');
  const iconColor = useColorModeValue('purple.500', 'purple.300');
  const [tokenData, setTokenData] = useState(null);
  const [purchasingBundle, setPurchasingBundle] = useState(false);

  // Define the function before using it in useEffect
  const fetchTokenStatus = async () => {
    try {
      const response = await axios.get('https://sbscoaches.com/api/tokens/status', {
        withCredentials: true
      });
      setTokenData(response.data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch token status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchTokenStatus();
    
    // Check for bundle param in URL (indicates purchase completion)
    const urlParams = new URLSearchParams(window.location.search);
    const bundleParam = urlParams.get('bundle');
    
    if (bundleParam) {
      // Clear the parameter to prevent repeated refreshes
      window.history.replaceState({}, document.title, window.location.pathname);
      
      // Fetch token status again and show success message
      fetchTokenStatus();
      toast({
        title: 'Purchase Successful',
        description: `Your ${bundleParam} token bundle has been added to your account.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  const handleManageSubscription = async () => {
    if (!isAuthenticated) {
      toast({
        title: 'Unauthorized',
        description: 'You must be logged in to manage your subscription.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch('https://sbscoaches.com/api/customer-portal', { 
        method: 'POST', 
        credentials: 'include' 
      });
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.url;
      } else {
        throw new Error(data.error || 'Failed to access the customer portal.');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to access the customer portal.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePurchaseBundle = async (bundleType) => {
    setPurchasingBundle(true);
    try {
      const response = await axios.post(
        'https://sbscoaches.com/api/tokens/purchase',
        { bundle_type: bundleType },
        { withCredentials: true }
      );
      window.location.href = response.data.checkout_url;
    } catch (error) {
      setPurchasingBundle(false);
      toast({
        title: 'Error',
        description: 'Failed to initiate bundle purchase.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const calculateTokenPercentage = () => {
    if (!tokenData) return 0;
    const { tokens, monthly_allocation } = tokenData.token_status;
    return (tokens / monthly_allocation) * 100;
  };

  const getTokenStatus = () => {
    const percentage = calculateTokenPercentage();
    if (percentage <= 15) return { color: 'red.500', message: 'Low Tokens', fontSize: 'xl' };
    if (percentage <= 30) return { color: 'yellow.500', message: 'Running Low', fontSize: 'xl' };
    return { color: 'green.500', message: 'Healthy', fontSize: 'xl' };
  };

  const handlePayment = () => {
    if (!isAuthenticated) {
      toast({
        title: 'Unauthorized',
        description: 'You must be logged in to schedule a coaching session.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    window.location.href = 'https://book.stripe.com/bIY00Ybsk7Ew14Q6ox';
  };

  return (
    <Box bg={bg} p={8} borderRadius="lg" boxShadow="lg" w="full" maxW="md" mx="auto" textAlign="center">
      <Heading mb={4} color={color}>Account Settings</Heading>
      <Tabs isFitted variant="enclosed" colorScheme="purple">
        <TabList mb="1em">
          <Tab _selected={{ color: "purple.500", bg: tabBg }}>Password</Tab>
          <Tab _selected={{ color: "purple.500", bg: tabBg }}>Subscription</Tab>
          <Tab _selected={{ color: "purple.500", bg: tabBg }}>Tokens</Tab>
          <Tab _selected={{ color: "purple.500", bg: tabBg }}>Coaching</Tab>
        </TabList>

        <TabPanels>
          {/* Change Password Tab */}
          <TabPanel>
            <VStack spacing={4}>
              <Icon as={FaLock} w={10} h={10} color={iconColor} />
              <Text color={color}>Change your account password</Text>
              <ResetPassword />
            </VStack>
          </TabPanel>

          {/* Manage Subscription Tab */}
          <TabPanel>
            <VStack spacing={4}>
              <Icon as={FaCreditCard} w={10} h={10} color={iconColor} />
              <Text color={color}>Manage and customize your subscription options</Text>
              <Button
                colorScheme="purple"
                size="lg"
                onClick={handleManageSubscription}
                width="full"
              >
                Manage Subscription
              </Button>
            </VStack>
          </TabPanel>

          {/* Token Management Tab */}
          <TabPanel>
            <VStack spacing={4}>
              <Icon as={FaCoins} w={10} h={10} color={iconColor} />
              <Text color={color}>Voice AI Token Management</Text>

              {/* Add the Refresh Balance button here */}
              <Button 
                leftIcon={<FaSync />} 
                size="sm" 
                onClick={fetchTokenStatus}
                variant="outline"
                colorScheme="purple"
                alignSelf="flex-end"
                mb={2}
              >
                Refresh Balance
              </Button>

              {/* Always show Stat and Progress if tokenData exists */}
              {tokenData?.token_status && (
                <>
                  <Stat>
                    <StatLabel>Available Tokens</StatLabel>
                    <StatNumber>{tokenData.token_status.tokens.toLocaleString()}</StatNumber>
                    <StatHelpText color={getTokenStatus().color} fontSize="xl">
                      {getTokenStatus().message}
                    </StatHelpText>
                  </Stat>

                  <Progress
                    value={calculateTokenPercentage()}
                    colorScheme={getTokenStatus().color}
                    size="sm"
                    mb={4}
                  />

                  {/* Show subscription info if available */}
                  {tokenData.subscription_info && (
                    <Text fontSize="sm" color="gray.500">
                      Next Reset: {new Date(tokenData.subscription_info.next_reset).toLocaleDateString()}
                    </Text>
                  )}

                  <Divider my={4} />
                </>
              )}

              {/* Always show bundle options */}
              <Stack spacing={4} width="full">
                {tokenData?.available_bundles && (
                  <>
                    <Button
                      colorScheme="purple"
                      onClick={() => handlePurchaseBundle('small')}
                      isLoading={purchasingBundle}
                    >
                      Small Bundle ({tokenData.available_bundles.small.tokens.toLocaleString()} tokens)
                    </Button>
                    <Button
                      colorScheme="purple"
                      variant="outline"
                      onClick={() => handlePurchaseBundle('large')}
                      isLoading={purchasingBundle}
                    >
                      Large Bundle ({tokenData.available_bundles.large.tokens.toLocaleString()} tokens)
                    </Button>
                  </>
                )}
                {!tokenData?.available_bundles && (
                  <>
                    <Button
                      colorScheme="purple"
                      onClick={() => handlePurchaseBundle('small')}
                      isLoading={purchasingBundle}
                    >
                      Small Bundle (180,000 tokens)
                    </Button>
                    <Button
                      colorScheme="purple"
                      variant="outline"
                      onClick={() => handlePurchaseBundle('large')}
                      isLoading={purchasingBundle}
                    >
                      Large Bundle (600,000 tokens)
                    </Button>
                  </>
                )}
              </Stack>
            </VStack>
          </TabPanel>

          {/* Coaching Sessions Tab */}
          <TabPanel>
            <VStack spacing={4}>
              <Icon as={FaCalendar} w={10} h={10} color={iconColor} />
              <Text color={color}>Schedule your coaching sessions</Text>
              <ScheduleCoaching calendlyUrl="https://calendly.com/saloncoach100/live-business-coaching-session" />
              <Button
                colorScheme="purple"
                size="lg"
                onClick={handlePayment}
                width="full"
              >
                Schedule a Coaching Session
              </Button>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AccountSettings;
