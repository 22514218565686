// src/App.js
import React, { useEffect } from 'react';
// import React from 'react';
import { APP_VERSION } from './version';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import StripeSuccessComponent from './Components/StripeSuccessComponent';
import theme from './theme';
import posthog from 'posthog-js';

// Import all components
import LandingPage from './Components/LandingPage';
import Signup from './Components/Signup';
import Login from './Components/Login';
import ForgotPassword from './Components/ForgotPassword';
import ResetPassword from './Components/ResetPassword';
import ContactUs from './Components/ContactUs';
import ThankYou from './Components/ThankYou';
import UserProfile from './Components/UserProfile';
import DataEntry from './Components/Performance/DataEntry';
import WelcomeComponent from './Components/WelcomeComponent';
import CoachFeedback from './Components/CoachFeedback';
import MonthlyCoachFeedback from './Components/MonthlyCoachFeedback';
import PeerComparison from './Components/PeerComparison';
import SubscriptionManagement from './Components/SubscriptionManagement';
import CreateMarketingComponent from './Components/CreateMarketingComponent';
import UserMarketingMaterials from './Components/UserMarketingMaterials';
import Community from './Components/Community';
import ChatComponent from './Components/ChatComponent';
import MySalonSuitesRaffleTerms from './Components/MySalonSuitesRaffleTerms';
import ThirtySecondBoost from './Components/ThirtySecondBoost';
import SevenDayChallenge from './Components/SevenDayChallenge';
import Unsubscribe from './Components/Unsubscribe';
import SquareSales from './Components/SquareSales';
import Performance from './Components/Performance';
import PastAppointments from './Components/PastAppointments';
import NotesComponent from './Components/Notes/NotesComponent';
import AdminDashboard from './Components/AdminDashboard';

// LiveKit imports
import AudioRoom from './Components/LiveKit/AudioRoom';

// Initialize PostHog as early as possible
posthog.init('phc_3goACnk6WFwMrTPMlmmxnMctjxlNrBUVSbm63p29Vzq', {
  api_host: 'https://app.posthog.com'
});

const AppContent = () => {
  return (
    <Box position="relative" minHeight="100vh" pb="60px">
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/mysalonsuitesraffleterms" element={<MySalonSuitesRaffleTerms />} />

        {/* Stripe success route */}
        <Route path="/stripe-success" element={<StripeSuccessComponent />} />

        {/* Protected routes */}
        <Route path="/welcome" element={<ProtectedRoute><WelcomeComponent /></ProtectedRoute>} />
        <Route path="/user-profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
        <Route path="/data-entry" element={<ProtectedRoute><DataEntry /></ProtectedRoute>} />
        <Route path="/performance" element={<ProtectedRoute><Performance /></ProtectedRoute>} />
        <Route path="/create-marketing" element={<ProtectedRoute><CreateMarketingComponent /></ProtectedRoute>} />
        <Route path="/user-marketing-materials" element={<ProtectedRoute><UserMarketingMaterials /></ProtectedRoute>} />
        <Route path="/coach-feedback" element={<ProtectedRoute><CoachFeedback /></ProtectedRoute>} />
        <Route path="/monthly-coach-feedback" element={<ProtectedRoute><MonthlyCoachFeedback /></ProtectedRoute>} />
        <Route path="/peer-comparison" element={<ProtectedRoute><PeerComparison /></ProtectedRoute>} />
        <Route path="/subscription-management" element={<ProtectedRoute><SubscriptionManagement /></ProtectedRoute>} />
        <Route path="/community" element={<ProtectedRoute><Community /></ProtectedRoute>} />
        <Route path="/chat" element={<ProtectedRoute><ChatComponent /></ProtectedRoute>} />
        <Route path="/thirty-second-boost-test" element={<ProtectedRoute> <ThirtySecondBoost onComplete={() => console.log('Boost Complete')} /></ProtectedRoute>} />
        <Route path="/seven-day-challenge" element={<ProtectedRoute><SevenDayChallenge /></ProtectedRoute>} />
        <Route path="/square-dashboard" element={<ProtectedRoute><SquareSales /></ProtectedRoute>} />
        <Route path="/past-appointments" element={<ProtectedRoute><PastAppointments /></ProtectedRoute>} />
        <Route path="/voice-assistant" element={<ProtectedRoute><Box className="container mx-auto max-w-4xl p-4"><AudioRoom /></Box></ProtectedRoute>} />
        <Route path="/notes" element={<ProtectedRoute><Box className="container mx-auto max-w-4xl p-4"><NotesComponent /></Box></ProtectedRoute>} />
        <Route path="/admin" element={<ProtectedRoute isAdmin><Box className="container mx-auto max-w-4xl p-4"><AdminDashboard /></Box></ProtectedRoute>} />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Box>
  );
};

const App = () => {
  useEffect(() => {
    const currentVersion = localStorage.getItem('app_version');
    if (currentVersion !== APP_VERSION) {
      localStorage.setItem('app_version', APP_VERSION);
      window.location.reload(true);
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
